import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";

const MetaContext = createContext({
  tutorialSeen: false,
  setTutorialSeen: () => {},
});

export const useMetaContext = () => useContext(MetaContext);

export const MetaContextProvider = ({ children }) => {
  const [tutorialSeen, setTutorialSeenState] = useState(false);

  const setTutorialSeen = (value) => {
    if (typeof value === "boolean") {
      setTutorialSeenState(value);
      localStorage.setItem("tutorialSeen", JSON.stringify(value));
    } else {
      console.warn("setTutorialSeen expects a boolean value.");
    }
  };

  useEffect(() => {
    try {
      const savedTutorialSeen = localStorage.getItem("tutorialSeen");
      if (savedTutorialSeen !== null) {
        const parsedValue = JSON.parse(savedTutorialSeen);
        if (typeof parsedValue === "boolean") {
          setTutorialSeenState(parsedValue);
        } else {
          localStorage.removeItem("tutorialSeen"); // Clean up invalid data
        }
      }
    } catch (error) {
      console.error(
        "Error loading or parsing tutorialSeen from localStorage:",
        error
      );
      localStorage.remove("tutorialSeen");
    }
  }, []);

  const metaContext = useMemo(
    () => ({
      tutorialSeen,
      setTutorialSeen,
    }),
    [tutorialSeen]
  );

  return (
    <MetaContext.Provider value={metaContext}>{children}</MetaContext.Provider>
  );
};
