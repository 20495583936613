// src/hooks/useVerifyAccess.js
import { useState, useEffect, useCallback } from "react";
import { useAuthContext } from "../contexts/AuthContext";
import { useCompanyContext } from "../contexts/CompanyContext";
import { useAuth } from "../hooks/useAuth";

const useVerifyAccess = () => {
  const { token } = useAuthContext();
  const { isAuthenticated, authenticationIsLoading } = useAuth();
  const { activeCompanyId } = useCompanyContext();

  const [state, setState] = useState({
    hasAccess: false,
    hasDemoAccess: false,
    hasAccessIsLoading: true,
    hasAccessError: null,
  });

  const verifyAccess = useCallback(async () => {
    if (
      authenticationIsLoading ||
      !isAuthenticated ||
      !token ||
      !activeCompanyId
    ) {
      setState((prev) => ({
        ...prev,
        hasAccess: false,
        hasAccessIsLoading: true, // Important! Vis Rapport button will bug if this is not set!
      }));
      return;
    }

    const abortController = new AbortController();

    setState((prev) => ({
      ...prev,
      hasAccessIsLoading: true,
      hasAccessError: null,
    }));

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/me/company/verify-access?company_id=${activeCompanyId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          signal: abortController.signal,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setState({
        hasAccess: Boolean(data.hasAccess),
        hasDemoAccess: Boolean(data.hasDemoAccess),
        hasAccessIsLoading: false,
        hasAccessError: null,
      });
    } catch (err) {
      if (err.name === "AbortError") return;

      setState((prev) => ({
        ...prev,
        hasAccess: false,
        hasAccessIsLoading: false,
        hasAccessError: err.message,
      }));
    } finally {
      setState((prev) => ({
        ...prev,
        hasAccessIsLoading: false,
      }));
    }
  }, [token, activeCompanyId, isAuthenticated, authenticationIsLoading]);

  useEffect(() => {
    const abortController = new AbortController();
    verifyAccess();
    return () => abortController.abort();
  }, [verifyAccess]);

  return {
    ...state,
    refetch: verifyAccess,
  };
};

export default useVerifyAccess;
