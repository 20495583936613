// src/hooks/useAuth.js

import { useState, useEffect } from "react";
import { useAuthContext } from "../contexts/AuthContext";

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const context = useAuthContext();

  if (!context) {
    throw new Error("useAuth must be used within a AuthContextProvider");
  }

  const { token, logout } = context;

  useEffect(() => {
    let isMounted = true;

    const verifyToken = async () => {
      if (!token) {
        setIsAuthenticated(false);
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/verify-token`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!isMounted) return;

        const isSuccesfull = response.ok;
        setIsAuthenticated(isSuccesfull);
        if (!isSuccesfull) logout();
      } catch (error) {
        if (!isMounted) return;

        setIsAuthenticated(false);
        logout();
      } finally {
        if (isMounted) setIsLoading(false);
      }
    };

    verifyToken();

    return () => {
      isMounted = false;
    };
  }, [token, logout]);

  return { isAuthenticated, authenticationIsLoading: isLoading };
};
