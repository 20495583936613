// src/pages/Login.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Typography,
  Box,
  Grid,
  CircularProgress,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import logo from "../logo-white.jpg";
import { useSnackbar } from "../contexts/SnackbarContext";

import { useAuthContext } from "../contexts/AuthContext";
import { useAuth } from "../hooks/useAuth";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { showSnackbar } = useSnackbar();

  const { isAuthenticated, authenticationIsLoading } = useAuth();
  const { login, authError } = useAuthContext();

  useEffect(() => {
    if (authenticationIsLoading) {
      return;
    }

    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, authenticationIsLoading, navigate]);

  const handleLogin = async () => {
    setError("");
    setLoading(true);
    const authenticationResponse = await login({ email, password });
    if (authenticationResponse.success) {
      showSnackbar(
        "Velkommen tilbage " + authenticationResponse.user.name,
        "success",
        {
          backgroundColor: "#006a61",
        }
      );
    } else {
      setError(authError);
    }
    setLoading(false);
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Grid
        container
        spacing={0}
        sx={{ height: "100vh", margin: 0, padding: 0 }}
      >
        {/* Branding Section */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            backgroundColor: "primary.main",
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: 0,
            padding: 0,
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={logo}
                alt={process.env.REACT_APP_COMPANY_NAME}
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  maxHeight: "75px",
                }}
              />
            </div>
            <Typography variant="h4" component="h1" sx={{ my: 2 }}>
              {process.env.REACT_APP_COMPANY_SLOGAN}
            </Typography>
          </Box>
        </Grid>

        {/* Content Section */}
        <Grid
          item
          xs={12}
          md={6}
          px={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "left",
              width: "100%",
              maxWidth: "530px",
            }}
          >
            <Typography component="h1" variant="h5" mt={2}>
              Log ind
            </Typography>
            <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={loading}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Adgangskode"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
                disabled={loading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                type="button"
                size="large"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 1, mb: 1 }}
                onClick={handleLogin}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Log ind"}
              </Button>
              {error && (
                <Typography variant="body2" color="error" sx={{ mt: 1, mb: 1 }}>
                  {error}
                </Typography>
              )}
              <Button
                color="primary"
                size="small"
                onClick={() => navigate("/glemt-adgangskode")}
              >
                Glemt adgangskode?
              </Button>
            </Box>

            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" color="textSecondary">
                Er din virksomhed ikke oprettet?{" "}
                <Button color="primary" onClick={() => navigate("/opret")}>
                  Opret konto
                </Button>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;
