import React, { useState, useEffect, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Button,
  Box,
  useTheme,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IntroVideoPlayer from "./IntroVideoPlayer"; // Ensure this component is properly implemented

// Styled Dialog with customized padding for content and actions
const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
  "& .MuiPaper-root": {
    boxShadow: theme.shadows[8],
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(2),
    maxHeight: "90vh",
    overflow: "auto",
    width: "100%",
    maxWidth: "md",
    // Remove flex properties to prevent interference with DialogTitle
    display: "block",
    position: "relative",
  },
}));

const ESGTutorial = ({ defaultOpen = false, onClose }) => {
  const [open, setOpen] = useState(defaultOpen);
  const theme = useTheme();

  // Synchronize internal open state with defaultOpen prop
  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  // Handlers wrapped with useCallback to prevent unnecessary re-renders
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        color="primary"
        sx={{ p: 0, m: 1, verticalAlign: "middle" }}
        aria-label="Open ESG Tutorial"
      >
        <HelpOutlineIcon
          sx={{
            fontSize: "1.5em",
            "@media (max-width: 600px)": { fontSize: "1em" },
          }}
        />
      </IconButton>

      <StyledDialog
        onClose={handleClose}
        aria-labelledby="esg-tutorial-dialog-title"
        open={open}
        maxWidth="md"
        fullWidth
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <DialogTitle
          id="esg-tutorial-dialog-title"
          sx={{
            m: 0,
            p: 2,
            color: theme.palette.primary.main,
            fontSize: "1.8rem",
            textAlign: "center",
            position: "relative",
          }}
        >
          Velkommen
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: theme.spacing(1),
              top: theme.spacing(1),
              color: theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              color: theme.palette.primary.main,
              fontSize: "1.2rem",
              mb: 3,
            }}
          >
            En kort video til at hjælpe dig godt i gang
          </Typography>
          <Box
            sx={{
              width: "90%",
              maxWidth: "900px",
              position: "relative",
              aspectRatio: "16 / 9", // Maintain 16:9 aspect ratio
            }}
          >
            <IntroVideoPlayer
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                border: `1px solid ${theme.palette.primary.main}`,
                // borderRadius: theme.shape.borderRadius,
              }}
            />
          </Box>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "center", p: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClose}
            sx={{
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            Start DitESG
          </Button>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default ESGTutorial;
