// src/components/Navbar.js
import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Select,
  Menu,
  MenuItem,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../logo.jpg";
import logoNoText from "../logo-no-text.png";

import { useSnackbar } from "../contexts/SnackbarContext";
import ESGTutorial from "./ESGTutorial";
import { useEffect } from "react";

import { useAuth } from "../hooks/useAuth";
import { useAuthContext } from "../contexts/AuthContext";
import { useCompanyContext } from "../contexts/CompanyContext";
import { useMetaContext } from "../contexts/MetaContext";
import { useUserContext } from "../contexts/UserContext";

const AddAccountingYearDialog = ({ open, onClose, accountingYears }) => {
  const theme = useTheme();
  const [activeAddableYearId, setActiveAddableYearId] = useState(null);
  const { showSnackbar } = useSnackbar();
  const { activeCompanyId, setCompanyData, setActiveAccountingYearId } =
    useCompanyContext();

  const [activeIdToNewIdFlag, setActiveIdToNewIdFlag] = useState(null); // null || integer

  const { token } = useAuthContext();

  const handleAddableYearChange = (e) => {
    setActiveAddableYearId(e.target.value);
  };

  const handleAddYear = async () => {
    if (!token) {
      showSnackbar("Du er ikke logget ind", "error");
    }

    if (!activeCompanyId) {
      showSnackbar(
        "Du prøver at oprette regnskabsår uden 'aktivt' selskab",
        "error"
      );
      return;
    }

    if (!activeAddableYearId) {
      // If no year is selected, show an error or return early
      showSnackbar(
        "Du mangler at vælge regnskabsår som skal tilføjes.",
        "error"
      );
      return;
    }
    const selectedYear = addableYears.find(
      (year) => year.id === activeAddableYearId
    );

    // Prepare the payload
    const payload = {
      start_date: selectedYear.from.toISOString().split("T")[0], // Format as YYYY-MM-DD
      end_date: selectedYear.to.toISOString().split("T")[0], // Format as YYYY-MM-DD
      company_id: activeCompanyId,
    };

    try {
      // Send a POST request to the backend to add the accounting year
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/me/company/add-accounting-year",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Replace with actual access token
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        try {
          const errorData =
            response.headers.get("content-length") > 0
              ? await response.json()
              : null; // Or provide a fallback error object
          console.error(errorData || "No error data provided");
        } catch (e) {
          console.error("Failed to parse error response:", e);
        }

        showSnackbar(
          `Oprettelse af regnskabsår fejlede. Kontakt venligst support eller prøv igen.`,
          "error"
        );
        return;
      }

      const newYear = await response.json();

      setCompanyData((prevCompanyData) => ({
        ...prevCompanyData, // Spread the existing company data
        accounting_years: [
          ...prevCompanyData.accounting_years,
          newYear.accounting_year,
        ], // Append the new accounting year
      }));
      onClose(); // Close the dialog
      showSnackbar("Regnskabsår er tilføjet", "success");

      setActiveIdToNewIdFlag(newYear.accounting_year.id);
    } catch (error) {
      console.error(error);
      showSnackbar(
        `Oprettelse af regnskabsår fejlede. Kontakt venligst support eller prøv igen.`,
        "error"
      );
    }
  };

  useEffect(() => {
    if (typeof activeIdToNewIdFlag === "number") {
      setActiveAccountingYearId(activeIdToNewIdFlag);
      setActiveIdToNewIdFlag(null);
    }
  }, [activeIdToNewIdFlag, setActiveAccountingYearId]);

  /**
   * HARD CODED RIGHT NOW...... We don't want half / Dynamic addable accounting years as for now.
   * And hard coded that it goes from year 2023 to this year  - 1.
   */
  const currentYear = new Date().getFullYear();
  const startYear = 2023;

  const generateYears = (startYear, endYear) => {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push({
        id: year - startYear + 1,
        shortValue: year,
        from: new Date(year, 0, 1), // January 1st of the year
        to: new Date(year, 11, 31), // December 31st of the year
      });
    }
    return years;
  };

  const [addableYears] = useState(generateYears(startYear, currentYear - 1));

  // Determine the first non-disabled value
  useEffect(() => {
    if (open) {
      const firstNonDisabledYear = addableYears.find(
        (year) =>
          !accountingYears.some(
            (existing_year) =>
              new Date(existing_year.end_date).getFullYear() === year.shortValue
          )
      );
      if (firstNonDisabledYear) {
        setActiveAddableYearId(firstNonDisabledYear.id);
      } else {
        setActiveAddableYearId(null);
      }
    }
  }, [open, accountingYears, addableYears]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: theme.shape.borderRadius,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
      }}
    >
      <DialogTitle
        sx={{
          padding: 0,
          margin: 0,
          paddingBottom: "25px",
          width: "100%",
          textAlign: "center",
          fontWeight: "bold",
          color: theme.palette.primary.main,
        }}
      >
        <Box
          sx={{
            display: "flex",
            padding: "15px",
            paddingRight: "75px",
            paddingLeft: "75px",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.palette.background.paper,
            borderBottom: "1px solid #B0B0B0",
            gap: "10px",
          }}
        >
          <img
            src={logoNoText}
            alt={process.env.REACT_APP_COMPANY_NAME}
            style={{
              maxWidth: "100%",
              height: "auto",
              maxHeight: "50px",
            }}
          />
          Tilføj regnskabsår
        </Box>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          padding: "50px",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          gap: "50px",
        }}
      >
        <Select
          value={activeAddableYearId || ""}
          onChange={handleAddableYearChange}
          sx={{
            alignSelf: "center", // Aligns the Select vertically in the center
            "& .MuiSelect-select": {
              padding: 0,
              paddingTop: "6px",
              paddingBottom: "6px",
              width: "200px",
              display: "flex",
              justifyContent: "center", // Center text horizontally
              alignItems: "center", // Center text vertically
            },
          }}
          MenuProps={{
            MenuListProps: {
              sx: {
                padding: 0, // Remove any padding inside the menu list
              },
            },
          }}
          inputProps={{ "aria-label": "Ny-Regnskabsår" }}
        >
          {addableYears?.map((year) => (
            <MenuItem
              sx={{
                textAlign: "center",
                alignSelf: "center",
                justifyContent: "center",
              }}
              disabled={
                !!accountingYears.find((existing_year) => {
                  return (
                    new Date(existing_year.end_date).getFullYear() ===
                    year.shortValue
                  );
                })
              }
              key={year.id}
              value={year.id}
            >
              {year.shortValue}
            </MenuItem>
          ))}
        </Select>
        <Button
          variant="contained"
          sx={{ width: "50%", alignSelf: "center" }}
          onClick={handleAddYear}
        >
          Tilføj
        </Button>
      </DialogContent>
    </Dialog>
  );
};

const Navbar = ({ onToggleDarkMode, isDataModified, setIsDataModified }) => {
  const navigate = useNavigate();
  const { isAuthenticated, authenticationIsLoading } = useAuth();
  const { logout } = useAuthContext();

  const isLoggedIn = !authenticationIsLoading && isAuthenticated;
  const {
    companyData,
    activeAccountingYearId,
    setActiveAccountingYearId,
    setActiveCompanyId,
    activeCompanyId,
  } = useCompanyContext();
  const { tutorialSeen, setTutorialSeen } = useMetaContext();
  const accountingYears = companyData?.accounting_years || [];
  const { userData } = useUserContext();

  const [anchorEl, setAnchorEl] = useState(null);
  const { showSnackbar } = useSnackbar();
  const [showTutorial, setShowTutorial] = useState(false);
  const location = useLocation();

  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    // Only show tutorial if explicitly passed in location state
    // and user hasn't seen it before
    if (location.state?.showTutorial && !tutorialSeen) {
      setTutorialSeen(true);
      // Clear the location state
      window.history.replaceState({}, document.title);
    }
  }, [location, tutorialSeen, setTutorialSeen]);

  const handleYearChange = (e) => {
    if (e.target.value === "Add button") {
      return;
    }

    if (isDataModified) {
      const confirmLeave = window.confirm(process.env.REACT_APP_UNSAVED_PROMPT);
      if (!confirmLeave) {
        e.preventDefault();
        return;
      }

      setIsDataModified(false);
    }

    setActiveAccountingYearId(e.target.value);
    navigate("/dashboard");
  };

  const handleMenuOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = (e) => {
    if (isDataModified) {
      const confirmLeave = window.confirm(process.env.REACT_APP_UNSAVED_PROMPT);
      if (!confirmLeave) {
        e.preventDefault();
        return;
      }
    }

    handleMenuClose();
    logout();
    showSnackbar("Du er nu logget ud", "success", {
      backgroundColor: "#fff",
      color: "#006a61",
    });
    navigate("/logind");
  };

  const handleLogoClick = (e) => {
    if (isDataModified) {
      const confirmLeave = window.confirm(process.env.REACT_APP_UNSAVED_PROMPT);
      if (!confirmLeave) {
        e.preventDefault();
        return;
      }

      setIsDataModified(false);
    }

    if (isLoggedIn) {
      navigate("/dashboard");
      return;
    }

    navigate("/");
  };

  const handleSettingsClick = (e) => {
    if (isDataModified) {
      const confirmLeave = window.confirm(process.env.REACT_APP_UNSAVED_PROMPT);
      if (!confirmLeave) {
        e.preventDefault();
        return;
      }

      setIsDataModified(false);
    }

    handleMenuClose();
    navigate("/indstillinger");
  };

  const handleAddAccountingYear = () => {
    setDialogOpen(true);
  };
  const handleAddCompany = () => {
    navigate("/opret");
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  // Added to remove the navbar if the user is not logged in
  if (!isLoggedIn) {
    return null;
  }

  return (
    <AppBar position="static" color="transparent" elevation={3}>
      <Toolbar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between", // This makes sure the logo is on the left and the right elements on the right
            alignItems: "center",
            width: "100%", // Take up the full width of the navbar
            gap: 2, // Adds consistent spacing between the items
          }}
        >
          {/* Left-aligned logo */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src={logo}
              alt={process.env.REACT_APP_COMPANY_NAME}
              height="40px"
              onClick={handleLogoClick}
              style={{
                cursor: "pointer",
                marginRight: "4rem",
                maxHeight: "50px",
              }}
              className="hidden md:block"
            />
            <Select
              value={companyData?.id || ""}
              onChange={(e) => {
                if (e.target.value === "Add button") return;
                setActiveCompanyId(e.target.value);
              }}
              displayEmpty
              variant="outlined"
              color="primary"
              sx={{
                marginTop: "5px",
                marginBottom: "5px",
                paddingRight: "10px",
                paddingLeft: "10px",
                fontSize: "1.4rem",
                color: "primary.main",
                "& .MuiSelect-select": {
                  paddingTop: "8px",
                  paddingBottom: "8px",
                },
                "@media (max-width: 600px)": {
                  maxWidth: "120px",
                  fontSize: "0.7rem",
                  paddingRight: "5px",
                  paddingLeft: "5px",
                },
              }}
              MenuProps={{
                MenuListProps: {
                  sx: {
                    padding: 0, // Remove any padding inside the menu list
                  },
                },
              }}
            >
              {(userData?.companies || []).map((company) => (
                <MenuItem
                  key={company.company_id}
                  value={company.company_id}
                  disabled={activeCompanyId === company.company_id}
                >
                  <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                    {company.name}
                    {company?.is_demo ? ", Demo" : null}
                  </div>
                </MenuItem>
              ))}
              <MenuItem
                onClick={handleAddCompany}
                value={"Add button"}
                sx={{
                  borderTop: "1px solid",
                  bordorColor: "primary.main",
                }}
              >
                <AddIcon sx={{ fontSize: "18px", color: "primary.main" }} />
                Opret virksomhed
              </MenuItem>
            </Select>
          </Box>

          {/* Center-aligned typography */}
          <Box sx={{ display: "flex", alignItems: "center" }}></Box>
          {/* Right-aligned Select and IconButton */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              "@media (max-width: 600px)": { gap: 0.5 },
            }}
          >
            <Typography
              variant="body"
              color="primary"
              className="hidden sm:flex"
              sx={{ textTransform: "uppercase" }}
            >
              VÆLG REGNSKABSÅR
            </Typography>
            <Select
              value={
                accountingYears.some(
                  (year) => year.id === activeAccountingYearId
                )
                  ? activeAccountingYearId
                  : ""
              }
              onChange={handleYearChange}
              sx={{
                alignSelf: "center", // Aligns the Select vertically in the center
                "& .MuiSelect-select": {
                  paddingTop: "6px", // Adjusts the padding to align with other elements
                  paddingBottom: "6px",
                  paddingRight: "8px",
                },
              }}
              MenuProps={{
                MenuListProps: {
                  sx: {
                    padding: 0, // Remove any padding inside the menu list
                  },
                },
              }}
              inputProps={{ "aria-label": "Regnskabsår" }}
            >
              {accountingYears?.map((year) => (
                <MenuItem
                  sx={{
                    textAlign: "center",
                    alignSelf: "center",
                    justifyContent: "center",
                  }}
                  key={year.id}
                  value={year.id}
                >
                  {new Date(year.end_date).getFullYear()}
                </MenuItem>
              ))}

              <MenuItem
                onClick={handleAddAccountingYear}
                value={"Add button"}
                sx={{
                  color: "black",
                  borderTop: "1px solid",
                  bordorColor: "primary.main",
                  margin: 0,
                  textAlign: "center",
                  alignSelf: "center",
                  fontSize: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AddIcon sx={{ fontSize: "18px", color: "primary.main" }} />
                <Typography variant="caption"> Tilføj regnskab</Typography>
              </MenuItem>
            </Select>
            <ESGTutorial
              defaultOpen={showTutorial}
              onClose={() => setShowTutorial(false)}
            />
            <IconButton color="primary" onClick={handleMenuOpen}>
              <AccountCircle
                sx={{
                  fontSize: "1.5em",
                  "@media (max-width: 600px)": { fontSize: "1em" },
                }}
              />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleSettingsClick}>Indstillinger</MenuItem>
              <MenuItem onClick={handleLogout}>Log ud</MenuItem>
            </Menu>
          </Box>
        </Box>
      </Toolbar>

      <AddAccountingYearDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        accountingYears={accountingYears}
      />
    </AppBar>
  );
};

export default Navbar;
