// PermissionsContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { useAuthContext } from "../contexts/AuthContext";
const RolesContext = createContext();

export const useRoles = () => useContext(RolesContext);

export const RolesProvider = ({ children }) => {
  const { token } = useAuthContext();
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    // Fetch roles from your backend
    if (!token) return;

    fetch(process.env.REACT_APP_API_URL + "/me/roles", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.role_names && Array.isArray(data?.role_names))
          setRoles(data.role_names);
      });
  }, [token]);

  return (
    <RolesContext.Provider value={roles}>{children}</RolesContext.Provider>
  );
};
