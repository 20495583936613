/**
 * Multiple accouting years and multiple companies supported
 * Uses saved accountingYearId and companyId.
 *
 * If companyId is not in the users companies id array (userContext), then defaults to company_ids[0]
 * accountingYearId also defaults to company.accounting_years[0]
 */

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useAuthContext } from "./AuthContext";
import { useAuth } from "../hooks/useAuth";
import { useUserContext } from "./UserContext";

const CompanyContext = createContext();

export const useCompanyContext = () => useContext(CompanyContext);

export const CompanyContextProvider = ({ children }) => {
  const { token } = useAuthContext();
  const { isAuthenticated, authenticationIsLoading } = useAuth();
  const [companyData, setCompanyData] = useState(null);
  const [loadingCompanyData, setLoadingCompanyData] = useState(false);
  const [companyDataError, setCompanyDataError] = useState(null);
  const [activeAccountingYearId, setActiveAccountingYearIdState] =
    useState(null);
  const [activeCompanyId, setActiveCompanyIdState] = useState(null);
  const { userData, userDataLoading } = useUserContext();
  const setActiveAccountingYearId = useCallback(
    (newAccountingYearId) => {
      if (loadingCompanyData) {
        console.warn(
          "You shouldn't modify accountingYearId while loading company data"
        );
        return;
      }

      if (
        typeof newAccountingYearId !== "number" &&
        newAccountingYearId !== null
      ) {
        throw new Error(
          "accountingYearId must be a number or null when using setActiveAccountingYearId"
        );
      }

      if (companyData === null || !companyData?.accounting_years?.length) {
        // No company data or no accounting years
        localStorage.removeItem("accountingYearId");
        setActiveAccountingYearIdState(null);
        return; // Stop further execution
      }

      const isValid = companyData.accounting_years.some(
        (year) => year.id === newAccountingYearId
      );

      if (isValid) {
        // Valid ID: Update state and localStorage
        setActiveAccountingYearIdState(newAccountingYearId);
        localStorage.setItem("accountingYearId", newAccountingYearId);
        return; // Stop further execution
      }

      // Default to the first accounting year
      const defaultYearId =
        companyData &&
        Array.isArray(companyData.accounting_years) &&
        companyData.accounting_years.length > 0
          ? companyData.accounting_years[0].id
          : null;
      setActiveAccountingYearIdState(defaultYearId);
      if (defaultYearId) {
        localStorage.setItem("accountingYearId", defaultYearId);
      } else {
        localStorage.removeItem("accountingYearId"); // Clean up if no valid default
      }
    },
    [loadingCompanyData, companyData]
  );

  const setActiveCompanyId = useCallback(
    (newCompanyId) => {
      if (userDataLoading) {
        console.warn(
          "You shouldn't set activeCompanyId while loading userData"
        );
        return;
      }

      if (typeof newCompanyId !== "number" && newCompanyId !== null) {
        throw new Error(
          "companyId must be a number or null when using setActiveCompanyId"
        );
      }

      if (userData?.companies?.some((e) => e.company_id === newCompanyId)) {
        setActiveCompanyIdState(newCompanyId);
        localStorage.setItem("companyId", newCompanyId);
      } else if (userData?.companies?.length > 0) {
        setActiveCompanyIdState(userData.companies[0].company_id);
        localStorage.setItem("companyId", userData.companies[0].company_id);
      } else {
        setActiveCompanyIdState(null);
        localStorage.removeItem("companyId");
      }
    },
    [userData, userDataLoading] // Updated dependency array
  );

  useEffect(() => {
    if (!userDataLoading && userData) {
      const savedCompanyId = localStorage.getItem("companyId");

      const savedCompanyIdFormatted = savedCompanyId
        ? Number(savedCompanyId)
        : null;

      setActiveCompanyId(savedCompanyIdFormatted);
    }
  }, [userDataLoading, userData, setActiveCompanyId]);

  useEffect(() => {
    if (
      !activeCompanyId ||
      authenticationIsLoading ||
      !isAuthenticated ||
      !token
    ) {
      setCompanyData(null);
      return;
    }

    const fetchCompanyData = async () => {
      try {
        setLoadingCompanyData(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/me/company?company_id=${activeCompanyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch company data");
        }

        const data = await response.json();
        setCompanyData(data);
        // console.log(data);
      } catch (error) {
        setCompanyDataError(error);
        console.error("Error fetching company data:", error);
      } finally {
        setLoadingCompanyData(false);
      }
    };

    fetchCompanyData();
  }, [authenticationIsLoading, isAuthenticated, token, activeCompanyId]);

  useEffect(() => {
    if (!loadingCompanyData) {
      const savedYearId = localStorage.getItem("accountingYearId");
      const savedYearIdFormatted = savedYearId ? Number(savedYearId) : null;

      if (!isNaN(savedYearIdFormatted)) {
        setActiveAccountingYearId(savedYearIdFormatted);
      } else {
        setActiveAccountingYearId(null);
      }
    }
  }, [loadingCompanyData, setActiveAccountingYearId]);

  const contextValue = useMemo(
    () => ({
      companyData,
      setCompanyData,
      activeAccountingYearId,
      setActiveAccountingYearId,
      loadingCompanyData,
      activeCompanyId,
      companyDataError,
      setActiveCompanyId,
    }),
    [
      companyData,
      setCompanyData,
      activeAccountingYearId,
      setActiveAccountingYearId,
      loadingCompanyData,
      activeCompanyId,
      setActiveCompanyId,
      companyDataError,
    ]
  );

  return (
    <CompanyContext.Provider value={contextValue}>
      {children}
    </CompanyContext.Provider>
  );
};
