import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { useAuthContext } from "../contexts/AuthContext";
import { useAuth } from "../hooks/useAuth";

const UserContext = createContext();

export const useUserContext = () => useContext(UserContext);

export const UserContextProvider = ({ children }) => {
  const { token } = useAuthContext();
  const { isAuthenticated, authenticationIsLoading } = useAuth();
  const [userData, setUserDataState] = useState(null);
  const [userDataError, setUserDataError] = useState(null);
  const [loading, setLoading] = useState(false);

  const setUserData = useCallback((newUserData) => {
    if (typeof newUserData !== "object") {
      throw new Error("User data must be an object or null");
    }
    setUserDataState(newUserData);
  }, []);

  const sanitizeUserUpdates = (updates) => {
    const sanitized = {};
    const allowedFields = ["email", "name"];

    allowedFields.forEach((field) => {
      if (updates[field] !== undefined) {
        sanitized[field] = updates[field];
      }
    });

    return sanitized;
  };

  const updateUserData = useCallback(
    async (updates) => {
      if (!token) {
        return { success: false, error: "No authentication token available" };
      }

      // Sanitize and validate input
      const sanitizedUpdates = sanitizeUserUpdates(updates);

      // Validate email format if present
      if (
        sanitizedUpdates.email &&
        !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(sanitizedUpdates.email)
      ) {
        return {
          success: false,
          error: "Invalid email format",
        };
      }

      // Validate name if present
      if (
        sanitizedUpdates.name &&
        (typeof sanitizedUpdates.name !== "string" ||
          sanitizedUpdates.name.length < 2)
      ) {
        return {
          success: false,
          error: "Name must be at least 2 characters long",
        };
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/me`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(sanitizedUpdates),
        });

        const data = await response.json();

        if (response.ok && data.id === userData?.id) {
          // Only update allowed fields in state
          setUserDataState((prevData) => ({
            ...prevData,
            ...sanitizedUpdates,
          }));
          return { success: true, data: sanitizedUpdates };
        }

        return {
          success: false,
          error: Object.entries(data)
            .map(([key, value]) => value[0])
            .join("\n"),
        };
      } catch (error) {
        return { success: false, error: error.message };
      }
    },
    [token, userData?.id]
  );

  const fetchUserData = useCallback(async () => {
    if (!token) {
      setUserData(null);
      return;
    }

    if (!isAuthenticated || authenticationIsLoading || !token) {
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }

      const data = await response.json();
      setUserDataState(data);
    } catch (error) {
      console.error(error);
      setUserDataError(error.message);
    } finally {
      setLoading(false);
    }
  }, [
    token,
    isAuthenticated,
    authenticationIsLoading,
    setUserData,
    setUserDataState,
  ]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const userContext = useMemo(
    () => ({
      userData,
      setUserData,
      userDataError,
      userDataLoading: loading,
      updateUserData,
      fetchUserData,
    }),
    [
      userData,
      setUserData,
      userDataError,
      loading,
      updateUserData,
      fetchUserData,
    ]
  );

  return (
    <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
  );
};
