import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../contexts/SnackbarContext";
import { useSearchParams } from "react-router-dom";
import { useAuthContext } from "../contexts/AuthContext";
import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  IconButton,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import logo from "../logo-white.jpg";
import { useUserContext } from "../contexts/UserContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const AcceptCompanyInvite = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const inviteToken = searchParams.get("invite-token");
  const { showSnackbar } = useSnackbar(); // Hent showSnackbar fra konteksten
  const { token, login, authError, reRenderToken, setToken } = useAuthContext();

  const [loading, setLoading] = useState(false);

  const [inviteInfo, setInviteInfo] = useState(null);
  const { userData, userDataLoading } = useUserContext();

  const [loginPassword, setLoginPassword] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const [createUserData, setCreateUserData] = useState({
    user_password: "",
    user_fullname: "",
    user_password_confirm: "",
  });
  const [createUserDataValidErrors, setCreateUserDataValidErrors] = useState({
    user_password: "",
    user_fullname: "",
    user_password_confirm: "",
  });
  const [createUserLoading, setCreateUserLoading] = useState(false);
  const [createUserError, setCreateUserError] = useState(null);

  const validateFullname = (fullname) => {
    if (!fullname) return "Navn er påkrævet.";
    return "";
  };

  const validatePassword = (password) => {
    if (!password) return "Adgangskode er påkrævet.";
    if (password.length < 8)
      return "Adgangskoden skal være mindst 8 tegn lang.";
    return "";
  };

  const validatePasswordConfirm = (password, passwordConfirm) => {
    if (!passwordConfirm) return "Bekræft adgangskode er påkrævet.";
    if (password !== passwordConfirm) return "Adgangskoderne matcher ikke.";
    return "";
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    if (!inviteToken) {
      return;
    }
    const getTokenInviteInfo = async () => {
      setLoading(true);
      const response = await fetch(
        process.env.REACT_APP_API_URL +
          `/company/users/invite/info?invite-token=${inviteToken}`
      );

      if (!response.ok) {
        // MANGLER
      }

      const responseJson = await response.json();
      setInviteInfo(responseJson);
      setLoading(false);
    };
    getTokenInviteInfo();
  }, [inviteToken]);

  const handleLogin = async () => {
    setLoginError("");
    setLoginLoading(true);
    const authenticationResponse = await login({
      email: inviteInfo?.email,
      password: loginPassword,
    });
    if (authenticationResponse.success) {
      showSnackbar(
        "Velkommen tilbage " + authenticationResponse.user.name,
        "success",
        {
          backgroundColor: "#006a61",
        }
      );
    } else {
      setLoginError(authError);
    }
    setLoginLoading(false);
  };

  const handleAcceptInvite = async () => {
    acceptInvite(token);
  };

  const acceptInvite = async (userToken) => {
    const response = await fetch(
      process.env.REACT_APP_API_URL +
        `/company/users/invite/accept?invite-token=${inviteToken}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    );

    if (response.ok) {
      const responseJson = await response.json();

      if (responseJson) {
        // MANGLER
      }
      showSnackbar(
        `Tillykke, ${userData?.name || ""}! Du er nu tilknyttet virksomheden ${
          inviteInfo.company.name
        }. Velkommen!`,
        "info"
      );
      searchParams.delete("invite-token");
      setSearchParams(searchParams);

      // setActiveCompanyId(responseJson.companyId)
      reRenderToken();
      navigate("/dashboard");
    } else {
      //Mangler
    }
  };

  const createUser = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/users", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: inviteInfo?.email,
        password: createUserData.user_password,
        name: createUserData.user_fullname,
      }),
    });

    const data = await response.json();
    return data;
  };

  const createUserAndAccept = async (event) => {
    event.preventDefault();
    setCreateUserError("");
    setCreateUserLoading(true);
    setLoading(true);
    try {
      const userResponseData = await createUser();

      if (userResponseData?.user && userResponseData?.token) {
        setToken(userResponseData.token);
        acceptInvite(userResponseData.token);
      } else {
        throw new Error(
          "No user object or token found",
          `userObject: ${userResponseData?.user}`,
          `token: ${userResponseData?.token}`
        );
      }
    } catch (error) {
      console.error(error);
      setCreateUserLoading(
        "Noget gik galt i oprettelsen. Prøv igen eller kontakt venligst support."
      );
    } finally {
      setCreateUserLoading(false);
      setLoading(false);
    }
  };

  return (
    <Container maxWidth={false} disableGutters>
      <Grid
        container
        spacing={0}
        sx={{ height: "100vh", margin: 0, padding: 0 }}
      >
        {/* Branding Section */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            backgroundColor: "primary.main",
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: 0,
            padding: 0,
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={logo}
                alt={process.env.REACT_APP_COMPANY_NAME}
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  maxHeight: "75px",
                }}
              />
            </div>
            <Typography variant="h4" component="h1" sx={{ my: 2 }}>
              {process.env.REACT_APP_COMPANY_SLOGAN}
            </Typography>
          </Box>
        </Grid>

        {/* Content Section */}
        <Grid
          item
          xs={12}
          md={6}
          px={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Typography component="h1" variant="h5" mb={3} mt={2}>
              Invitation til{" "}
              {inviteInfo?.company?.name
                ? inviteInfo?.company?.name
                : "Virksomhed"}
            </Typography>

            {loading || userDataLoading ? (
              <Box>Tænker...</Box>
            ) : inviteInfo?.accepted ? (
              <Box>
                <Typography component="div" variant="subtitle1" mb={3} mt={2}>
                  Invitationen, som tilhører {inviteInfo?.email}, er allerede
                  accepteret.
                </Typography>
              </Box>
            ) : inviteInfo?.expired ? (
              <Box>
                <Typography component="div" variant="subtitle1" mb={3} mt={2}>
                  Linket, som tilhører {inviteInfo?.email}, er desværre udløbet.
                </Typography>
              </Box>
            ) : token && inviteInfo?.email !== userData?.email ? (
              <Box>
                <Box>
                  <Typography component="div" variant="subtitle1" mb={3} mt={2}>
                    Du er logget ind på den forkerte konto. Linket er knyttet
                    til {inviteInfo?.email}, men du er logget ind som{" "}
                    {userData?.email}.
                  </Typography>
                </Box>
              </Box>
            ) : token && inviteInfo?.email === userData?.email ? (
              <Box>
                <Typography component="div" variant="subtitle1" mb={3} mt={2}>
                  Ved at klikke på knappen vil du, {userData.email}, blive
                  tilføjet som medlem i virksomheden {inviteInfo?.company?.name}
                  .
                </Typography>

                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    sx={{ width: "100%" }}
                    onClick={handleAcceptInvite}
                  >
                    {" "}
                    Accepter og bliv medlem
                  </Button>
                </Box>
              </Box>
            ) : !token && inviteInfo?.userExists ? (
              <Box>
                <Typography component="div" variant="subtitle1" mb={3} mt={2}>
                  Linket tilhører {inviteInfo?.email}.
                </Typography>
              </Box>
            ) : !token && !inviteInfo?.userExists ? (
              <Box>
                <Typography component="div" variant="subtitle1" mb={3} mt={2}>
                  Linket er knyttet til {inviteInfo?.email}, men der findes
                  ingen tilhørende konto. Opret en konto for at acceptere
                  invitationen.
                </Typography>
              </Box>
            ) : null}

            {token ? (
              <>
                {/* // <Box
              //   sx={{
              //     borderTop: "1px solid #ccc", // Light gray border
              //     mt: 2, // Optional: Adds some margin above the box
              //     pt: 2, // Optional: Adds padding inside the box
              //     display: "flex",
              //     gap: 2,
              //   }}
              // >
              //   <Button
              //     variant="outlined"
              //     onClick={() => {
              //       navigate("/dashboard");
              //     }}
              //   >
              //     Tilbage til dashboard
              //   </Button>
              //   <Button onClick={logout}>Log ud</Button>
              // </Box> */}
              </>
            ) : inviteInfo?.accepted || inviteInfo?.expired ? (
              <Button
                onClick={() => {
                  navigate("/logind");
                }}
              >
                Log ind
              </Button>
            ) : inviteInfo?.userExists ? (
              <Box>
                <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={inviteInfo?.email}
                    disabled={loginLoading}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Adgangskode"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    autoComplete="current-password"
                    value={loginPassword}
                    onChange={(e) => setLoginPassword(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleLogin();
                      }
                    }}
                    disabled={loginLoading}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    type="button"
                    size="large"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 1, mb: 1 }}
                    onClick={handleLogin}
                    disabled={loginLoading}
                  >
                    {loginLoading ? <CircularProgress size={24} /> : "Log ind"}
                  </Button>
                  {loginError && (
                    <Typography
                      variant="body2"
                      color="error"
                      sx={{ mt: 1, mb: 1 }}
                    >
                      {loginError}
                    </Typography>
                  )}
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => navigate("/glemt-adgangskode")}
                  >
                    Glemt adgangskode?
                  </Button>
                </Box>
              </Box>
            ) : !inviteInfo?.userExists ? (
              <Box>
                <Box component="form" onSubmit={createUserAndAccept}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="fullname"
                    label="Navn"
                    name="fullname"
                    value={createUserData.user_fullname}
                    onChange={(e) => {
                      const value = e.target.value;
                      setCreateUserData({
                        ...createUserData,
                        user_fullname: value,
                      });
                      setCreateUserDataValidErrors({
                        ...createUserDataValidErrors,
                        fullname: validateFullname(value),
                      });
                    }}
                    error={!!createUserDataValidErrors.fullname}
                    helperText={createUserDataValidErrors.fullname}
                    disabled={createUserLoading}
                  />

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email adresse"
                    name="email"
                    type="email"
                    value={inviteInfo?.email || ""}
                    disabled={true}
                  />

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label="Adgangskode"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    value={createUserData.user_password}
                    onChange={(e) => {
                      const value = e.target.value;
                      setCreateUserData({
                        ...createUserData,
                        user_password: value,
                      });
                      setCreateUserDataValidErrors({
                        ...createUserDataValidErrors,
                        password: validatePassword(value),
                        passwordConfirm:
                          createUserData.user_password_confirm.length > 0
                            ? validatePasswordConfirm(
                                value,
                                createUserData.user_password_confirm
                              )
                            : "",
                      });
                    }}
                    error={!!createUserDataValidErrors.password}
                    helperText={createUserDataValidErrors.password}
                    disabled={createUserLoading}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="user_password_confirm"
                    label="Bekræft adgangskode"
                    name="user_password_confirm"
                    type={showConfirmPassword ? "text" : "password"}
                    onChange={(e) => {
                      const value = e.target.value;
                      setCreateUserData({
                        ...createUserData,
                        user_password_confirm: value,
                      });
                      setCreateUserDataValidErrors({
                        ...createUserDataValidErrors,
                        passwordConfirm: validatePasswordConfirm(
                          createUserData.user_password,
                          value
                        ),
                      });
                    }}
                    value={createUserData.user_password_confirm}
                    error={!!createUserDataValidErrors.passwordConfirm}
                    helperText={createUserDataValidErrors.passwordConfirm}
                    disabled={createUserLoading}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Button
                    type="submit"
                    size="large"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 1, mb: 2 }}
                    disabled={
                      createUserLoading ||
                      Object.values(createUserData).some((value) => !value) ||
                      Object.values(createUserDataValidErrors).some(
                        (error) => error
                      )
                    }
                  >
                    {createUserLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      "Opret bruger og accepter invitation"
                    )}
                  </Button>
                  {createUserError && (
                    <Typography variant="body2" color="error">
                      {createUserError}
                    </Typography>
                  )}
                </Box>
              </Box>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AcceptCompanyInvite;
